import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import TermosText from '../components/TermosText'
import SEO from '../components/seo'

const TermosPage = () => {
	return (
		<Layout>
			<Header />
			<SEO title='Termo de consentimento para tratamento de dados pessoais' />

			<div className='p-8 text-gray-700 leading-7'>
				<TermosText />
			</div>
		</Layout>
	)
}

export default TermosPage
