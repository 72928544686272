import React from 'react'

const TermosText = () => {
	return (
		<>
			<p className='text-lg text-gray-900 text-center mb-4'>TERMO DE CONSENTIMENTO PARA TRATAMENTO DE DADOS PESSOAIS</p>
			<span>
				Este documento visa registrar a manifestação livre, informada e inequívoca pela qual o Titular concorda com o
				tratamento de seus dados pessoais, sensíveis ou não, para finalidade específica de tramitação de documentos via
				aplicativo Vidaas Box para realizar assinatura digital de documentos diversos em formato PDF, através de
				aplicativos web e dispositivos móveis, em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados
				Pessoais (LGPD).
				<br />
				<br />
				Ao dar seu ACEITE no presente termo, o Titular consente e concorda que a empresa VALID SOLUÇÕES S.A, bem como
				suas coligadas ou filiadas, inscrita no CNPJ/MF sob nº 33.113.309/0001-47, com sede na Cidade e Estado do Rio de
				Janeiro, na Rua Peter Lund, 146/202, São Cristóvão, CEP 20.930-901, e-mail communication@valid.com, doravante
				denominada Controladora, tome decisões referentes ao tratamento de seus dados pessoais, bem como realize o
				tratamento de seus dados pessoais, sensíveis ou não, envolvendo operações como as que se referem a coleta,
				produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento,
				arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação,
				transferência, difusão ou extração.
				<br />
				<br />
				Dados Pessoais
				<br />
				O Controlador fica autorizado a tomar decisões referentes ao tratamento e a realizar o tratamento dos seguintes
				dados pessoais do Titular:
				<br />
				Nome completo.
				<br />
				Data de nascimento.
				<br />
				Número e imagem da Carteira de Identidade (RG).
				<br />
				Número e imagem do Cadastro de Pessoas Físicas (CPF).
				<br />
				Número e imagem da Carteira Nacional de Habilitação (CNH).
				<br />
				Número e imagem da Carteira Profissional Registrada em Órgão de Classe.
				<br />
				Número e imagem da Carteira de Seguro e Convênio Médico.
				<br />
				Fotografia 3x4.
				<br />
				Estado civil.
				<br />
				Nível de instrução ou escolaridade.
				<br />
				Endereço completo.
				<br />
				Números de telefone, WhatsApp e endereços de e-mail.
				<br />
				Nome de usuário e senha específicos para uso dos serviços do Controlador.
				<br />
				Comunicação, verbal e escrita, mantida entre o Titular e o Controlador.
				<br />
				Referente à saúde ou à vida sexual, dado genético ou biométrico.
				<br />
				<br />
				Finalidades do Tratamento dos Dados
				<br />
				O tratamento dos dados pessoais listados neste termo tem as seguintes finalidades:
				<br />
				Possibilitar a assinatura digital de documentos diversos pelo ao Titular em todo território nacional.
				<br />
				Possibilitar que o Controlador receba e processe as informações e dados para autenticação e assinatura dos
				documentos ao Titular.
				<br />
				Possibilitar que o Controlador envie ou forneça ao Titular seus produtos e serviços, de forma remunerada ou
				gratuita.
				<br />
				Possibilitar que o Controlador estruture, teste, promova e faça propaganda de produtos e serviços,
				personalizados ou não ao perfil do Titular.
				<br />
				<br />
				Compartilhamento de Dados
				<br />
				O Controlador fica autorizado a compartilhar os dados pessoais do Titular com outros agentes de tratamento de
				dados, caso seja necessário para as finalidades listadas neste termo, observados os princípios e as garantias
				estabelecidas pela Lei nº 13.709.
				<br />
				<br />
				Término do Tratamento dos Dados
				<br />
				O Controlador poderá manter e tratar os dados pessoais do Titular durante todo o período em que forem
				pertinentes ao alcance das finalidades listadas neste termo. Dados pessoais anonimizados, sem possibilidade de
				associação ao indivíduo, poderão ser mantidos por período indefinido. O Titular poderá solicitar via e-mail ou
				correspondência ao Controlador, a qualquer momento, que sejam eliminados os dados pessoais não anonimizados do
				Titular. O Titular fica ciente de que poderá ser inviável ao Controlador continuar o fornecimento de produtos ou
				serviços ao Titular a partir da eliminação dos dados pessoais.
				<br />
				<br />
				Direitos do Titular
				<br />
				O Titular tem direito a obter do Controlador, em relação aos dados por ele tratados, a qualquer momento e
				mediante requisição: I - confirmação da existência de tratamento; II - acesso aos dados; III - correção de dados
				incompletos, inexatos ou desatualizados; IV - anonimização, bloqueio ou eliminação de dados desnecessários,
				excessivos ou tratados em desconformidade com o disposto na Lei nº 13.709; V - portabilidade dos dados a outro
				fornecedor de serviço ou produto, mediante requisição expressa e observados os segredos comercial e industrial,
				de acordo com a regulamentação do órgão controlador; V - portabilidade dos dados a outro fornecedor de serviço
				ou produto, mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os
				segredos comercial e industrial; VI - eliminação dos dados pessoais tratados com o consentimento do titular,
				exceto nas hipóteses previstas no art. 16 da Lei nº 13.709; VII - informação das entidades públicas e privadas
				com as quais o controlador realizou uso compartilhado de dados; VIII - informação sobre a possibilidade de não
				fornecer consentimento e sobre as consequências da negativa; IX - revogação do consentimento, nos termos do § 5º
				do art. 8º da Lei nº 13.709.
				<br />
				<br />
				Direito de Revogação do Consentimento
				<br />
				Este consentimento poderá ser revogado pelo Titular, a qualquer momento, mediante solicitação via e-mail ou
				correspondência ao Controlador.
				<br />
				<br />
				Padrões de Segurança
				<br />
				Para garantir a segurança das informações em posse da VALID, esta adota todas as medidas de segurança esperadas
				para manter os dados pessoais seguros e protegidos de acessos não autorizados e de situações acidentais ou
				ilícitas de destruição, perda, alteração, comunicação ou qualquer forma de tratamento inadequado ou ilícito.
				Nesse sentido, garantimos o trânsito das informações em canal criptográfico, bem como seu armazenamento em
				ambientes físicos e digitais protegidos com rígidos controles de acesso impostos pela legislação aplicável. Com
				Quem Compartilharemos Os Dados A VALID poderá compartilhar as informações coletadas, nas seguintes hipóteses:
				<br />
				Com empresas parceiras e credenciadas pelo ICP – Brasil, no desenvolvimento das atividades comerciais da VALID,
				bem como com órgãos e entidades fiscalizadoras para o cumprimento das obrigações legais da VALID para a emissão
				da certificação digital;
				<br />
				Mediante ordem judicial ou pelo requerimento de autoridades administrativas que detenham competência legal para
				sua requisição.
				<br />
				Retenção dos Dados Pessoais Coletados
				<br />
				A VALID poderá reter informações e cópias dos documentos assinados pelos clientes que possuem certificados
				digitais Vidaas Box, para as seguintes funcionalidades:
				<br />
				Acesso à plataforma por autenticação via QR code com certificados digitais Vidaas;
				<br />
				Uploads de documento PDF para a plataforma Vidaas Box;
				<br />
				Realizar assinatura digital no documento PDF carregado na plataforma;
				<br />
				Download de documento PDF assinado digitalmente ou não;
				<br />
				Envio por e-mail à terceiros do documento PDF assinado digitalmente ou não;
				<br />
				Sessão aberta por tempo determinado para realizar operações sem que seja necessário autenticação a cada
				operação.
				<br />
				Os dados pessoais coletados e documentos arquivados pela VALID por meio do aplicativo e/ou site serão
				automaticamente excluídos de seus servidores quando deixarem de ser úteis para os fins para os quais foram
				coletadas, ou quando o usuário solicitar a eliminação de seus dados pessoais, respeitando determinação do órgão
				regulador para o tempo de tratamento e retenção dos dados coletados.
				<br />
				Sem prejuízo, as informações poderão ser conservadas para cumprimento de obrigação legal ou regulatória,
				transferência a terceiro – desde que respeitados os requisitos de tratamento de dados – e uso exclusivo da
				VALID, vedado seu acesso por terceiro, desde que anonimizadas.
				<br />
				<br />
				Atualizações
				<br />
				Última atualização em 12/06/2020.
			</span>
		</>
	)
}

export default TermosText
